<style>
html,
body {
  overflow-x: hidden;
}
.menuGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 310px);
  grid-gap: 20px;
  justify-content: center;
}
.menuGrid button {
  position: absolute;
  opacity: 0;
  z-index: 100000;
  width: 100%;
  height: 100%;
}
.menuGrid .item {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
  width: 310px;
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.menuGrid .item .info {
  font-size: 12px;
  padding: 5px 12px;
  display: grid;
  align-content: space-betweenx;
  grid-template-rows: auto 25px;
}
.menuGrid .item .info h3 {
  margin: 2px 0;
  font-size: 16px;
}
.menuGrid .item .info .discount {
  display: block;
  margin-top: 2px;

  padding: 1.5px 5px;
  background-color: crimson;
  font-size: 8px;
  width: 35px;
  text-align: center;
  font-weight: bold;
  color: white;
  border-radius: 5px;
}

.menuGrid .item .desc {
  padding: 5px 0;
  bottom: 10px;
  grid-gap: 30px;
  justify-self: right;
}
.menuGrid .item img {
  width: 100%;
}
.item_version_2 a h3 {
  font-size: 19px;
}
.item_version_2 a img {
  transition: 0.3s;
}
.modal.fade {
  padding-top: 7.5%;
  width: 310px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.subactive {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
  border: 4.5px solid #e54750;
}
.subactiveCata {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);

  border-bottom: 2px solid crimson;
}
@media (max-width: 700px) {
  .modal.fade {
    padding-top: 23%;
  }
  .lang {
    position: relative;
    top: -30px;
  }
}
.language {
  font-weight: bold;
  font-size: 17px;
}
@media (max-width: 450px) {
  .menuGrid .item {
    grid-template-columns: 1fr;
    padding-bottom: 10px;
  }
  .menuGrid .item .desc {
    position: static;

    justify-content: space-between;
  }
  .menuGrid .item .info {
    font-size: 12px;
    padding: 12px 12px;
    padding-top: 14px;
    display: flex;
    justify-content: space-between;
  }
  .menuGrid .item .info h3 {
    margin: 0;
    font-size: 16px !important;
  }
  .menuGrid .item .info .desc {
    padding: 0;
  }
  .menuGrid .item .info strong {
    font-size: 14px;
  }
}
strong {
  font-weight: bold;
  color: chocolate;
  font-size: 12px;
}

:root {
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background: gray;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
::-webkit-scrollbar-thumb:horizontal {
  background: #e54750;
  border-radius: 10px;
}

.button {
  color: #e3dfe9;
  font-size: 10px;
  background: #e54750;
  padding: 10px 5px;
  box-shadow: 3px 3px 30px rgba(118, 96, 168, 0.2);
  border-radius: 10px;

  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease, color 0.2s ease;
  height: 40px;
}

.button:hover {
  color: #dbd7e0;
  transform: translateY(3px);
  box-shadow: 3px 3px 30px rgba(118, 96, 168, 0.17);
}

.arrow {
  width: 13px;
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(180deg);
}

.button p {
  display: inline;
}

.dropdown {
  text-align: center;
  position: relative;
  color: #e3dfe9;
  font-size: 10px;
  background: white;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 15px;
  display: block;
  cursor: pointer;

  transform: scale(0.01);
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease, box-shadow 0.3s ease 0.15s;
  transform-origin: center top;
  overflow: hidden;
}

.dropdown.open {
  transform: scale(1);
  opacity: 1;
  box-shadow: 3px 3px 30px rgba(118, 96, 168, 0.15);
}

.dropdown a {
  position: relative;
  color: #6c6185;
  text-decoration: none;
  display: block;
  padding: 12.5px 30px;
  transition: color 0.2s ease, background-color 0.2s ease padding-left 0.2s ease;
  overflow: hidden;
}

.dropdown a.clicked {
  padding-left: 35px;
  color: white;
}

.dropdown a:hover {
  color: white;
  padding-left: 35px;
}

/* span {
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0px;
  position: absolute;
  background: #987cd9;
  transition: width 0.4s ease;
  border-radius: 1px;
}

span.clicked {
  width: 5px;
} */
.swal2-content,
.vt-notification-container {
  font-family: "kurdishFont" !important;
}
</style>

<template>
  <div id="app">
    <div
      class="upBtton"
      style="
        width: 50px;
        height: 50px;
        position: fixed;
        bottom: 10px;
        right: 10px;
        z-index: 10000;
      "
    >
      <button
        :style="'background-color:' + btnColor"
        class="scrollBtn d-none btn text-light mb-2"
        @click="scrollToUp()"
      >
        <i
          :style="'color: ' + btnForeColor"
          class="fa-solid fa-arrow-up-long"
        ></i>
      </button>
    </div>
    <!-- Reservation Modal -->
    <div
      style="z-index: 10000000; width: 100%"
      class="modal fade"
      id="reservation"
      tabindex="-1"
      aria-labelledby="reservationModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              id="reservationModalLabel"
              :class="
                $i18n.locale == 'ku' || $i18n.locale == 'ar' ? 'kurd' : ''
              "
            >
              {{ $t("reserveForm.reserve") }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div
            class="modal-body"
            :class="
              $i18n.locale == 'ku' || $i18n.locale == 'ar' ? 'kurd' : 'eng'
            "
          >
          <h5 v-if="!reserveAvailability" class="text-danger text-center">{{$t("reserveForm.noReservation")}}</h5>
            <div class="container-fluid" v-if="reserveAvailability">
              <div class="row">
                <div class="col">
                  <form
                    @submit.prevent="reserve()"
                    :class="
                      $i18n.locale == 'ku' || $i18n.locale == 'ar'
                        ? 'rtl'
                        : 'ltr'
                    "
                  >
                    <div class="form-group">
                      <label for="">{{ $t("reserveForm.name") }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="name"
                        v-model="reservation.customerName"
                      />
                      <small
                        v-if="!reservation.customerName"
                        class="text-danger"
                        >*{{ $t("reserveForm.required") }}</small
                      >
                    </div>
                    <div class="form-group">
                      <label for="">{{ $t("reserveForm.phone") }}</label>
                      <input
                        type="number"
                        class="form-control"
                        id="phone"
                        v-model="reservation.phoneNumber"
                      />
                      <small v-if="!reservation.phoneNumber" class="text-danger"
                        >*{{ $t("reserveForm.required") }}</small
                      >
                      <small
                        v-if="
                          reservation.phoneNumber
                            ? reservation.phoneNumber.length != 11
                            : false
                        "
                        class="text-danger"
                        >*{{ $t("reserveForm.numberValid") }}</small
                      >
                    </div>
                    <div class="form-group">
                      <label for="">{{ $t("reserveForm.date") }}</label>
                      <input
                        type="date"
                        class="form-control"
                        id="name"
                        v-model="reservation.date"
                      />
                      <small v-if="!reservation.date" class="text-danger"
                        >*{{ $t("reserveForm.required") }}</small
                      >
                      <small v-if="isPast" class="text-danger"
                        >*{{ $t("reserveForm.required") }}</small
                      >
                    </div>
                    <div class="form-group">
                      <label for="">{{ $t("reserveForm.hour") }}</label>
                      <select
                        class="form-control"
                        v-model="reservation.resevTime"
                      >
                        <option
                          :value="hour"
                          v-for="(hour, i) in hourRange"
                          :key="i"
                        >
                          {{ hour }}:00
                        </option>
                      </select>

                      <small
                        v-if="!(reservation.resevTime > 0)"
                        class="text-danger"
                        >*{{ $t("reserveForm.required") }}</small
                      >
                    </div>
                    <div class="form-group">
                      <label for="">{{ $t("reserveForm.visitors") }}</label>
                      <select class="form-control" v-model="reservation.people">
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                      </select>
                      <small v-if="!reservation.people" class="text-danger"
                        >*{{ $t("reserveForm.required") }}</small
                      >
                    </div>
                    <div class="form-group">
                      <label for="">{{ 'Note' }}</label>
                      <textarea
                        class="form-control"
                        id="note"
                        rows="4"
                        maxlength="125"
                        v-model="reservation.note"
                      ></textarea>
                    </div>
                    <button
                      data-bs-dismiss="modal"
                      :disabled="!isValidate"
                      :style="
                        'background-color: ' +
                        btnColor +
                        ';color: ' +
                        btnForeColor
                      "
                      class="btn w-100"
                      type="submit"
                    >
                      {{ $t("reserveForm.send") }}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Delivery Modal -->
    <div
      style="z-index: 10000000; width: 100%"
      class="modal fade bd-example-modal-lg"
      id="exampleModalCenter2"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel2"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog bd-example-modal-lg"
        role="document"
      >
        <div class="modal-content">
          <div
            class="modal-header"
            :class="
              $i18n.locale == 'ku' || $i18n.locale == 'ar' ? 'kurd' : 'eng'
            "
          >
            <h5
              class="modal-title"
              id="exampleModalLabel"
              v-if="$store.state.cart.length > 0 && !checkOutVisibility"
            >
              {{ $t("youritems") }}
            </h5>
            <h5
              class="modal-title"
              id="exampleModalLabel"
              v-if="!$store.state.cart.length"
            >
              {{ $t("emptyCart") }}
            </h5>
            <h5
              class="modal-title"
              id="exampleModalLabel"
              v-if="checkOutVisibility"
            >
              {{ $t("checkOut") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form
            @submit.prevent="submitOrder()"
            action=""
            :class="
              $i18n.locale == 'ku' || $i18n.locale == 'ar' ? 'rtl' : 'ltr'
            "
          >
            <div class="modal-body">
              <h6
                class="text-danger text-center"
                v-if="$store.state.cart.length > 0 && outOfZone"
              >
                {{ $t("outOfZone") }}
              </h6>

              <div
                class="d-flex justify-content-center"
                v-if="!$store.state.cart.length"
              >
                <i class="far fa-frown" style="font-size: 24px"></i>
                <p class="mt-1 ml-1">{{ $t("emptyCart") }}</p>
              </div>
              <!-- showing items in the cart -->
              <div
                :class="checkOutVisibility ? 'd-none' : ''"
                class="col col-12 mt-3"
                v-for="x in this.$store.state.cart"
                :key="'hama-' + x.itemId + 'item' + x.menuOrderSubAddOns.length"
              >
                <div class="d-flex justify-content-between">
                  <div class="d-flex pictureAndName">
                    <img
                      :class="
                        $i18n.locale == 'ku' || $i18n.locale == 'ar'
                          ? 'ml-3'
                          : ''
                      "
                      :src="$hostname + x.imageLink"
                      style="width: 50px; height: 50px; border-radius: 8px"
                      alt=""
                    />
                    <div class="content ml-2 mt-1">
                      <div class="row">
                        <p
                          :class="
                            $i18n.locale == 'ku' || $i18n.locale == 'ar'
                              ? 'kurd'
                              : ''
                          "
                        >
                          {{ x["name" + getCartLang()] }}
                        </p>
                      </div>
                      <div
                        v-if="x.menuOrderSubAddOns"
                        class="row"
                        style="margin-top: -20px"
                      >
                        <ul>
                          <li
                            class="text-secondary"
                            style="display: inline"
                            v-for="a in x.menuOrderSubAddOns"
                            :key="a.name"
                          >
                            {{ a["name" + getCartLang()] }},
                          </li>
                        </ul>
                      </div>
                      <div class="row">
                        <div class="theButtons ml-2" style="margin-top: -10px">
                          <i
                            v-if="
                              !($i18n.locale == 'ku' || $i18n.locale == 'ar')
                            "
                            :class="
                              $i18n.locale == 'ku' || $i18n.locale == 'ar'
                                ? 'ml-2'
                                : 'mr-2'
                            "
                            @click="reduceQuantity(x)"
                            style="font-size: 20px"
                            class="fa fa-minus-circle text-danger"
                          ></i>

                          <!-- RTL add Btn -->
                          <i
                            v-if="$i18n.locale == 'ku' || $i18n.locale == 'ar'"
                            :class="
                              $i18n.locale == 'ku' || $i18n.locale == 'ar'
                                ? 'ml-2'
                                : 'mr-2'
                            "
                            style="font-size: 20px"
                            class="fa fa-plus-circle text-success"
                            @click="addQuantity(x)"
                          ></i>
                          <span style="font-size: 13px">{{ x.quantity }}</span>
                          <i
                            v-if="
                              !($i18n.locale == 'ku' || $i18n.locale == 'ar')
                            "
                            :class="
                              $i18n.locale == 'ku' || $i18n.locale == 'ar'
                                ? 'mr-2'
                                : 'ml-2'
                            "
                            style="font-size: 20px"
                            class="fa fa-plus-circle text-success"
                            @click="addQuantity(x)"
                          ></i>
                          <!-- RTL Minus Btn -->
                          <i
                            v-if="$i18n.locale == 'ku' || $i18n.locale == 'ar'"
                            :class="
                              $i18n.locale == 'ku' || $i18n.locale == 'ar'
                                ? 'mr-2'
                                : 'ml-2'
                            "
                            @click="reduceQuantity(x)"
                            style="font-size: 20px"
                            class="fa fa-minus-circle text-danger"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="thePrice mt-2">
                    <p>{{ myNumber(x.price * x.quantity) }}</p>
                  </div>
                </div>
              </div>
              <!-- Delivery form -->
              <div class="col col-12" v-if="checkOutVisibility">
                <div class="text-left">
                  <i
                    @click="checkOutVisibility = false"
                    class="fa fa-arrow-circle-left"
                  ></i>
                </div>
                <div
                  id="map-wrap"
                  style="height: 150px; width: 100%"
                  v-if="fee && fee.restLat && fee.restLong"
                >
                  <l-map
                    :zoom="12"
                    :center="[
                      location.coords.latitude,
                      location.coords.longitude,
                    ]"
                  >
                    <l-tile-layer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    ></l-tile-layer>

                    <l-marker
                      :lat-lng="[fee.restLat, fee.restLong]"
                      :icon="icon"
                    >
                      <l-popup>PE Restaurant</l-popup>
                    </l-marker>
                    <l-marker
                      :lat-lng="[
                        location.coords.latitude,
                        location.coords.longitude,
                      ]"
                      :icon="icon2"
                    >
                    </l-marker>
                  </l-map>
                </div>

                <div class="form-group col-12">
                  <label for="">{{ $t("name") }}</label>
                  <input
                    required
                    type="text"
                    class="form-control"
                    name=""
                    id=""
                    v-model="orderBody.name"
                  />
                </div>
                <div class="form-group col-12">
                  <label for="">{{ $t("address") }}</label>
                  <textarea
                    placeholder="E.g. Qaiwan City, Building A, Floor 3, Apt.2"
                    required
                    type="text"
                    class="form-control"
                    name=""
                    id=""
                    v-model="orderBody.address"
                  ></textarea>
                </div>
                <div class="form-group col-12">
                  <label for="">{{ $t("phoneNumber") }}</label>
                  <input
                    v-model="orderBody.phoneNumber"
                    class="form-control"
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder="XXXX-XXX-XXXX"
                    pattern="[0-9]{4}-[0-9]{3}-[0-9]{4}"
                    required
                  />
                </div>
                <div class="form-group col-12">
                  <label for="">{{ $t("note") }}</label>
                  <textarea
                    v-model="orderBody.note"
                    class="form-control"
                  ></textarea>
                </div>
              </div>

              <!-- Delivery totals -->
              <div
                v-if="$store.state.cart.length"
                :class="outOfZone ? 'd-none' : ''"
              >
                <div class="d-flex justify-content-between">
                  <!-- Total -->
                  <h6 class="mt-3">{{ $t("total") }}:</h6>
                  <h6
                    :class="
                      $i18n.locale == 'ku' || $i18n.locale == 'ar'
                        ? 'kurd text-left'
                        : 'eng'
                    "
                    v-if="$store.state.cart.length"
                    class="text-dark mt-3"
                  >
                    {{ myNumber($store.state.finalTotal) }}
                  </h6>
                </div>
                <div
                  style="margin-top: -10px"
                  class="d-flex justify-content-between"
                  v-if="$store.state.cart.length && fee"
                >
                  <!-- Discount -->
                  <h6 class="mt-3 text-danger">{{ $t("discount") }}:</h6>
                  <h6
                    :class="
                      $i18n.locale == 'ku' || $i18n.locale == 'ar'
                        ? 'kurd text-left'
                        : 'eng'
                    "
                    v-if="$store.state.cart.length"
                    class="text-danger mt-3"
                  >
                    %{{ fee.discountRate }}
                  </h6>
                </div>
                <div
                  style="margin-top: -10px"
                  class="d-flex justify-content-between"
                  v-if="$store.state.cart.length && fee"
                >
                  <!-- Sub Total -->
                  <h6 class="mt-3">{{ $t("subTotal") }}:</h6>
                  <h6
                    :class="
                      $i18n.locale == 'ku' || $i18n.locale == 'ar'
                        ? 'kurd text-left'
                        : 'eng'
                    "
                    v-if="$store.state.cart.length"
                    class="text-dark mt-3"
                  >
                    {{
                      myNumber(
                        $store.state.finalTotal -
                          $store.state.finalTotal * (fee.discountRate / 100)
                      )
                    }}
                  </h6>
                </div>
                <div
                  style="margin-top: -10px"
                  class="d-flex justify-content-between"
                  v-if="$store.state.cart.length && fee"
                >
                  <!-- Delivery -->
                  <h6 class="mt-3">{{ $t("delivery") }}:</h6>
                  <h6
                    :class="
                      $i18n.locale == 'ku' || $i18n.locale == 'ar'
                        ? 'kurd text-left'
                        : 'eng'
                    "
                    v-if="$store.state.cart.length"
                    class="text-dark mt-3"
                  >
                    {{ myNumber(estimatedDeliveryFee) }}
                  </h6>
                </div>
                <div
                  style="margin-top: -10px"
                  class="d-flex justify-content-between"
                  v-if="$store.state.cart.length && fee"
                >
                  <!-- Final Total -->
                  <h6 class="mt-3" style="font-weight: bold">
                    {{ $t("finalTotal") }}:
                  </h6>
                  <h6
                    style="font-weight: bold"
                    :class="
                      $i18n.locale == 'ku' || $i18n.locale == 'ar'
                        ? 'kurd text-left'
                        : 'eng'
                    "
                    v-if="$store.state.cart.length"
                    class="text-dark mt-3"
                  >
                    {{
                      myNumber(
                        $store.state.finalTotal -
                          $store.state.finalTotal * (fee.discountRate / 100) +
                          estimatedDeliveryFee
                      )
                    }}
                  </h6>
                </div>
              </div>
            </div>
            <div
              class="modal-footer"
              :class="
                $i18n.locale == 'ku' || $i18n.locale == 'ar' ? 'kurd' : 'eng'
              "
            >
              <!-- <button
                v-if="!checkOutVisibility"
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                {{ $t("close") }}
              </button> -->
              <button
                @click="clearCart()"
                v-if="!checkOutVisibility && $store.state.cart.length"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                {{ $t("clearCart") }}
              </button>
              <button
                :disabled="outOfZone"
                type="button"
                class="btn"
                :style="
                  'background-color: ' + btnColor + '; color: ' + btnForeColor
                "
                @click="checkOutVisibility = true"
                v-if="!checkOutVisibility && $store.state.cart.length"
              >
                {{ $t("checkOut") }}
              </button>
              <button
                @click="submitOrder()"
                :data-dismiss="formValid ? 'modal' : ''"
                type="submit"
                class="btn d-block m-auto"
                :style="
                  'background-color: ' + btnColor + '; color: ' + btnForeColor
                "
                v-if="checkOutVisibility"
              >
                {{ $t("submit") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Dining Modal -->
    <div
      style="z-index: 1000000; width: 100%"
      class="modal fade bd-example-modal-lg"
      id="exampleModalCenter3"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel2"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog bd-example-modal-lg"
        role="document"
      >
        <div class="modal-content">
          <div
            class="modal-header"
            :class="
              $i18n.locale == 'ku' || $i18n.locale == 'ar' ? 'kurd' : 'eng'
            "
          >
            <h5
              class="modal-title"
              id="exampleModalLabel"
              v-if="$store.state.cart.length > 0 && !checkOutVisibility"
            >
              {{ $t("youritems") }} {{ $t("forTable") }} {{ routeTableNumber }}
            </h5>
            <h5
              class="modal-title"
              id="exampleModalLabel"
              v-if="!$store.state.cart.length"
            >
              {{ $t("emptyCart") }}
            </h5>
            <h5
              class="modal-title"
              id="exampleModalLabel"
              v-if="checkOutVisibility"
            >
              {{ $t("checkOut") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form
            @submit.prevent="submiDiningtOrder()"
            action=""
            :class="
              $i18n.locale == 'ku' || $i18n.locale == 'ar' ? 'rtl' : 'ltr'
            "
          >
            <div class="modal-body">
              <div
                class="d-flex justify-content-center"
                v-if="!$store.state.cart.length"
              >
                <i class="far fa-frown" style="font-size: 24px"></i>
                <p class="mt-1 ml-1">{{ $t("emptyCart") }}</p>
              </div>
              <div
                :class="checkOutVisibility ? 'd-none' : ''"
                class="col col-12 mt-3"
                v-for="x in this.$store.state.cart"
                :key="'hama-' + x.itemId"
              >
                <div class="d-flex justify-content-between">
                  <div class="d-flex pictureAndName">
                    <img
                      :class="
                        $i18n.locale == 'ku' || $i18n.locale == 'ar'
                          ? 'ml-3'
                          : ''
                      "
                      :src="$hostname + x.imageLink"
                      style="width: 50px; height: auto; border-radius: 8px"
                      alt=""
                    />
                    <div class="content ml-2 mt-1">
                      <div class="row">
                        <p
                          :class="
                            $i18n.locale == 'ku' || $i18n.locale == 'ar'
                              ? 'kurd'
                              : ''
                          "
                        >
                          {{ x["name" + getCartLang()] }}
                        </p>
                        <div class="theButtons ml-2" style="margin-top: -10px">
                          <i
                            v-if="
                              !($i18n.locale == 'ku' || $i18n.locale == 'ar')
                            "
                            :class="
                              $i18n.locale == 'ku' || $i18n.locale == 'ar'
                                ? 'ml-2'
                                : 'mr-2'
                            "
                            @click="reduceQuantity(x)"
                            style="font-size: 20px"
                            class="fa fa-minus-circle text-danger"
                          ></i>

                          <!-- RTL add Btn -->
                          <i
                            v-if="$i18n.locale == 'ku' || $i18n.locale == 'ar'"
                            :class="
                              $i18n.locale == 'ku' || $i18n.locale == 'ar'
                                ? 'ml-2'
                                : 'mr-2'
                            "
                            style="font-size: 20px"
                            class="fa fa-plus-circle text-success"
                            @click="addQuantity(x)"
                          ></i>
                          <span style="font-size: 13px">{{ x.quantity }}</span>
                          <i
                            v-if="
                              !($i18n.locale == 'ku' || $i18n.locale == 'ar')
                            "
                            :class="
                              $i18n.locale == 'ku' || $i18n.locale == 'ar'
                                ? 'mr-2'
                                : 'ml-2'
                            "
                            style="font-size: 20px"
                            class="fa fa-plus-circle text-success"
                            @click="addQuantity(x)"
                          ></i>
                          <!-- RTL Minus Btn -->
                          <i
                            v-if="$i18n.locale == 'ku' || $i18n.locale == 'ar'"
                            :class="
                              $i18n.locale == 'ku' || $i18n.locale == 'ar'
                                ? 'mr-2'
                                : 'ml-2'
                            "
                            @click="reduceQuantity(x)"
                            style="font-size: 20px"
                            class="fa fa-minus-circle text-danger"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="thePrice mt-2">
                    <p>{{ myNumber(x.price * x.quantity) }}</p>
                  </div>
                </div>
              </div>

              <div class="col col-12" v-if="checkOutVisibility">
                <div class="text-left">
                  <i
                    @click="checkOutVisibility = false"
                    class="fa fa-arrow-circle-left"
                  ></i>
                </div>
                <div class="form-group col-12">
                  <label for="">{{ $t("note") }}</label>
                  <textarea
                    v-model="orderBody.note"
                    class="form-control"
                  ></textarea>
                </div>
              </div>
              <!-- Delivery totals -->
              <div v-if="$store.state.cart.length">
                <div class="d-flex justify-content-between">
                  <!-- Total -->
                  <h6 class="mt-3">{{ $t("total") }}:</h6>
                  <h6
                    :class="
                      $i18n.locale == 'ku' || $i18n.locale == 'ar'
                        ? 'kurd text-left'
                        : 'eng'
                    "
                    v-if="$store.state.cart.length"
                    class="text-dark mt-3"
                  >
                    {{ myNumber($store.state.finalTotal) }}
                  </h6>
                </div>
                <div
                  style="margin-top: -10px"
                  class="d-flex justify-content-between"
                  v-if="$store.state.cart.length && fee"
                >
                  <!-- Discount -->
                  <h6 class="mt-3 text-danger">{{ $t("discount") }}:</h6>
                  <h6
                    :class="
                      $i18n.locale == 'ku' || $i18n.locale == 'ar'
                        ? 'kurd text-left'
                        : 'eng'
                    "
                    v-if="$store.state.cart.length"
                    class="text-danger mt-3"
                  >
                    %{{ fee.discountRate }}
                  </h6>
                </div>

                <div
                  style="margin-top: -10px"
                  class="d-flex justify-content-between"
                  v-if="$store.state.cart.length && fee"
                >
                  <!-- Final Total -->
                  <h6 class="mt-3">{{ $t("finalTotal") }}:</h6>
                  <h6
                    :class="
                      $i18n.locale == 'ku' || $i18n.locale == 'ar'
                        ? 'kurd text-left'
                        : 'eng'
                    "
                    v-if="$store.state.cart.length"
                    class="text-dark mt-3"
                  >
                    {{
                      myNumber(
                        $store.state.finalTotal -
                          $store.state.finalTotal * (fee.discountRate / 100)
                      )
                    }}
                  </h6>
                </div>
              </div>
            </div>
            <div
              class="modal-footer"
              :class="
                $i18n.locale == 'ku' || $i18n.locale == 'ar' ? 'kurd' : 'eng'
              "
            >
              <!-- <button
                v-if="!checkOutVisibility"
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                {{ $t("close") }}
              </button> -->
              <button
                @click="clearCart()"
                v-if="!checkOutVisibility && $store.state.cart.length"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                {{ $t("clearCart") }}
              </button>
              <button
                type="button"
                class="btn"
                :style="
                  'background-color: ' + btnColor + '; color: ' + btnForeColor
                "
                @click="checkOutVisibility = true"
                v-if="!checkOutVisibility && $store.state.cart.length"
              >
                {{ $t("checkOut") }}
              </button>
              <button
                @click="submiDiningtOrder()"
                :data-dismiss="true ? 'modal' : ''"
                type="submit"
                class="btn d-block m-auto"
                :style="
                  'background-color: ' + btnColor + '; color: ' + btnForeColor
                "
                v-if="checkOutVisibility"
              >
                {{ $t("submitDiginig") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <header
      class="header black_nav clearfix element_to_stick;"
      :style="'height: 140px !important; background-color:' + mainBackColor"
    >
      <div class="container">
        <!-- Opacity Mask Menu Mobile -->
        <div class="text-right lang">
          <br />
          <br />
          <div class="d-flex justify-content-end" style="height: 30px">
            <!-- Dinning Basket-->
            <!-- <div
              class="myBasket"
              style="margin-right: 5px"
              v-if="routeTableNumber"
            >
              <button
                data-toggle="modal"
                data-target="#exampleModalCenter3"
                class="btn"
                :style="'border: solid ' + btnColor"
                style="border-radius: 10px"
              >
                <span
                  :style="'color:' + btnColor"
                  class="mr-1"
                  v-if="$store.state.cart"
                  >{{ $store.state.cart.length }}</span
                >
                <i
                  :style="'color:' + btnColor"
                  class="fa fa-shopping-basket"
                ></i>
              </button>
            </div> -->

            <!-- Delivery Basket-->
            <!-- <div
              class="myBasket"
              style="margin-right: 5px"
              v-if="!routeTableNumber"
            >
              <button
                @click="getCoordinations()"
                data-toggle="modal"
                data-target="#exampleModalCenter2"
                class="btn"
                :style="'border: solid ' + btnColor"
                style="border-radius: 10px"
              >
                <span
                  :style="'color:' + btnColor"
                  class="mr-1"
                  v-if="$store.state.cart"
                  >{{ $store.state.cart.length }}</span
                >
                <i
                  :style="'color:' + btnColor"
                  class="fa fa-shopping-basket"
                ></i>
              </button>
            </div> -->
            <div class="langs">
              <div
                class="button text-center"
                :style="
                  'background-color:' + btnColor + '; color: ' + btnForeColor
                "
              >
                <p
                  class="text-center"
                  style="font-size: 14px"
                  v-if="this.$i18n.locale == 'en'"
                >
                  English
                </p>
                <p
                  class="text-center"
                  style="font-size: 14px; font-family: kurdishFont"
                  v-if="this.$i18n.locale == 'ku'"
                >
                  کوردی
                </p>
                <p
                  class="text-center"
                  style="font-size: 14px; font-family: kurdishFont"
                  v-if="this.$i18n.locale == 'ar'"
                >
                  العربیة
                </p>
                <p
                  class="text-center"
                  style="font-size: 14px"
                  v-if="this.$i18n.locale == 'tu'"
                >
                  Türkçe
                </p>
                <i class="fa fa-globe ml-2"></i>
              </div>
              <div class="dropdown">
                <a
                  v-if="
                    this.$i18n.locale == 'ar' ||
                    this.$i18n.locale == 'ku' ||
                    this.$i18n.locale == 'tu'
                  "
                  class="language"
                  @click="switchLocale('en')"
                  >English
                </a>
                <a
                  :class="
                    $i18n.locale == 'ku' || $i18n.locale == 'ar'
                      ? 'kurd'
                      : 'kurd'
                  "
                  v-if="
                    avKu &&
                    (this.$i18n.locale == 'ar' ||
                      this.$i18n.locale == 'en' ||
                      this.$i18n.locale == 'tu')
                  "
                  class="language"
                  @click="switchLocale('ku')"
                  >کوردی
                </a>
                <a
                  :class="
                    $i18n.locale == 'ku' || $i18n.locale == 'ar'
                      ? 'kurd'
                      : 'kurd'
                  "
                  v-if="
                    avAr &&
                    (this.$i18n.locale == 'en' ||
                      this.$i18n.locale == 'ku' ||
                      this.$i18n.locale == 'tu')
                  "
                  class="language"
                  @click="switchLocale('ar')"
                  >العربیة</a
                >
                <a
                  v-if="
                    avTu &&
                    (this.$i18n.locale == 'ar' ||
                      this.$i18n.locale == 'en' ||
                      this.$i18n.locale == 'ku')
                  "
                  class="language"
                  @click="switchLocale('tu')"
                  >Türkçe</a
                >
              </div>
            </div>
          </div>

          <div
            class="socials d-flex justify-content-center"
            style="font-size: 30px; margin-top: 30px"
          >
            <a :href="contact.facebook" v-if="contact && contact.facebook">
              <i
                :style="'color:' + mainForeColor"
                class="fab fa-facebook mr-4"
              ></i>
            </a>
            <a :href="contact.instagram" v-if="contact && contact.instagram">
              <i
                :style="'color:' + mainForeColor"
                class="fab fa-instagram mr-4"
              ></i>
            </a>
            <a :href="contact.snapchat" v-if="contact && contact.snapchat">
              <i
                :style="'color:' + mainForeColor"
                class="fab fa-snapchat mr-4"
              ></i>
            </a>
            <a v-if="contact && contact.tikTok" :href="contact.tikTok"
              ><i
                :style="'color:' + mainForeColor"
                class="fab fa-tiktok mr-4"
              ></i
            ></a>

            <a :href="'tel:' + contact.phone" v-if="contact && contact.phone">
              <i
                :style="'color:' + mainForeColor"
                class="fa fa-phone-alt mr-4"
              ></i>
            </a>
          </div>
          <!-- Button trigger modal -->
          <button
            v-if="reserveVisibility && !routeTableNumber"
            :class="$i18n.locale == 'ku' || $i18n.locale == 'ar' ? 'kurd' : ''"
            class="btn w-100 mt-2"
            data-bs-toggle="modal"
            data-bs-target="#reservation"
            :style="'background-color:' + btnColor + '; color:' + btnForeColor"
          >
            <i class="fa-solid fa-calendar-check"></i> &nbsp;&nbsp;<span>{{
              $t("reserveForm.reserve")
            }}</span>
          </button>
        </div>
        <!-- /top_menu -->
        <a href="#0" class="open_close">
          <div id="logo">
            <a href="index.html">
              <img :src="$hostname + logo" style="width: 75px; height: auto" />
            </a>
          </div>
        </a>
        <!-- <nav class="main-menu">
          <div id="header_menu">
            <a href="#0" class="open_close">
              <i class="icon_close"></i><span>Menu</span>
            </a>
            <a href="index.html"
              ><img src="img/logo.svg" width="162" height="35" alt=""
            /></a>
          </div>
        </nav> -->
      </div>
    </header>
    <!-- /header -->

    <router-view :style="'background-color :' + mainBackColor"></router-view>
    <!-- <router-view></router-view>  -->
    <hr />

    
  </div>
</template>

<script>
import $ from "jquery";
import { icon } from "leaflet";
export default {
  created() {
    this.getReserveAvailabilities();
    this.getAvailabilities();
   


    this.$Axios.get(`${this.$hostname}/getLogo`).then((response) => {
      console.log(response.data);
      document.title = response.data.title;
      var link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      link.href = `${this.$hostname}${response.data.imageLink.replaceAll(
        "\\",
        "/"
      )}`;
      this.logo = response.data.imageLink;
      this.getAvailabilities();
      this.getReverseVisibility()
      
    });
  },
  data() {
    return {
      //#e54750
      colors: null,
      logo: "",
      mainBackColor: "#FFFFFF",
      mainForeColor: "#000000",
      btnColor: "#e54750",
      btnForeColor: "#FFFFFF",

      circle: {
        center: [35.5726317, 45.3502206],
        radius: 4000,
        color: "red",
      },
      circle2: {
        center: [35.5726317, 45.3502206],
        radius: 6000,
        color: "green",
      },
      circle3: {
        center: [35.5726317, 45.3502206],
        radius: 10000,
        color: "blue",
      },
      icon: icon({
        iconUrl:
          "https://cdn2.iconfinder.com/data/icons/building-vol-2/512/restaurant-512.png",
        iconSize: [30, 30],
        iconAnchor: [16, 37],
      }),
      icon2: icon({
        iconUrl:
          "https://www.columbia.edu/content/sites/default/files/styles/cu_crop/public/content/about/map-marker.png?itok=_qI1T9cg",
        iconSize: [30, 30],
        iconAnchor: [16, 37],
      }),

      location: null,
      gettingLocation: false,
      errorStr: null,

      estimatedDeliveryFee: 0,
      outOfZone: false,

      formValid: false,

      languages: null,

      avEn: false,
      avKu: false,
      avAr: false,
      avTu: false,

      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(","),
      toggled: false,
      reloaded: false,

      cardObj: {
        itemId: null,
        name: null,
        price: null,
        imageLink: null,
        quantity: 1,
      },

      checkOutVisibility: false,

      orderBody: {
        orderBy: null,
        orderTotal: this.$store.state.finalTotal,
        date: new Date(),

        tableNumber: parseInt(0),
        orderType: "Delivery",
        orderState: "Pending",

        note: null,
        name: null,
        address: null,
        phoneNumber: null,

        deliveryFee: null,
        discountRate: null,

        MenuOrderSubs: this.$store.state.cart,
      },

      routeTableNumber: null,
      contact: null,
      fee: null,

      reservation: {
        customerName: null,
        phoneNumber: null,
        date: null,
        resevTime: 0,
        people: 5,
        note: null,
      },
      availabilities: [],
      hourRange: [],

      reserveAvailability: null,
      reserveVisibility: false,
    };
  },
  computed: {
    isValidate() {
      return (
        this.reservation.customerName &&
        this.reservation.phoneNumber &&
        !this.reservation.customerName.length != 11 &&
        this.reservation.date &&
        this.reservation.resevTime &&
        this.reservation.people &&
        new Date(this.reservation.date) > new Date()
      );
    },
    isPast() {
      //console.log(this.cleanDate(reservation.date) < this.cleanDate(new Date()));

      console.log("res date " + new Date(this.reservation.date).setHours(0, 0, 0, 0))
      console.log("now date " + new Date().setHours(0, 0, 0, 0))
      return new Date(this.reservation.date).setHours(0, 0, 0, 0) <new Date().setHours(0, 0, 0, 0);
    },
  },
  methods: {
    cleanDate(date) {
      let thisDate = new Date(date);
      return thisDate.toLocaleDateString("en-GB");
    },
    async getReverseVisibility(){
            const {data} = await  this.$Axios.get(`${this.$hostname}/getReserveVisibility`);
            this.reserveVisibility = data.reserveVisibility;
        },
    async reserve() {
      var question = "Are you sure you want to submit this reservation?";
      var feedback =
        "Your reservation Submitted successfully, we will contact you soon!";

      if (this.$i18n.locale == "ku") {
        question = "ئایا دڵنیایی لە ناردنی داواكارییەكەت؟";
        feedback =
          "داواكارییەكەت بە سەركەوتووی نێردرا! بە زووترین كات پەیوەندیت پێوە ئەكەین";
      } else if (this.$i18n.locale == "ar") {
        question = "هل أنت متأكد أنك تريد تقديم هذا الطلب؟";
        feedback = " تم إرسال الطلب بنجاح ، سنتواصل معك قريبًا!";
      } else if (this.$i18n.locale == "tu") {
        question =
          "Sipariş başarıyla gönderildi, yakında sizinle iletişime geçeceğiz!";
        feedback = "Sipariş başarıyla gönderildi";
      }

      this.$confirm(question, "Question", "question")
        .then(() => {
          this.$loading(true);
          try {
            this.$Axios.post(
              `${this.$hostname}/newReservation`,
              this.reservation
            );
            this.reservation.customerName = null;
            this.reservation.phoneNumber = null;
            this.reservation.date = null;
            this.reservation.resevTime = 0;
            this.reservation.people = 5;
            this.hourRange = [];
            this.$alert(feedback, "Success", "success");
            this.$loading(false);
          } catch (error) {
            console.log(error);
            this.$loading(false);
          }
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
    async getAvailabilities() {
      const { data } = await this.$Axios.get(
        `${this.$hostname}/getReservationTime`
      );
      this.availabilities = data;
    },

    async getReserveAvailabilities() {
      const { data } = await this.$Axios.get(
        `${this.$hostname}/getAvailability`
      );
      this.reserveAvailability = data.reserveAvailability;

     // console.log("**************" + this.reserveAvailability)
      
    },


    

    calcCrow(x1, lon1, x2, lon2) {
      var R = 6371; // km
      var dLat = this.toRad(x2 - x1);
      var dLon = this.toRad(lon2 - lon1);
      var lat1 = this.toRad(x1);
      var lat2 = this.toRad(x2);
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) *
          Math.sin(dLon / 2) *
          Math.cos(lat1) *
          Math.cos(lat2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      console.log(d + " Km");
      return d;
    },
    toRad(Value) {
      return (Value * Math.PI) / 180;
    },

    getFee() {
      this.$Axios
        .get(`${this.$hostname}/getFees`)
        .then((Response) => {
          this.fee = Response.data;
          this.circle.radius = this.fee.circleOneDistance;
          this.circle.center = [this.fee.restLat, this.fee.restLong];
          this.circle2.radius = this.fee.circleTwoDistance;
          this.circle2.center = [this.fee.restLat, this.fee.restLong];
          this.circle3.radius = this.fee.circleThreeDistance;
          this.circle3.center = [this.fee.restLat, this.fee.restLong];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCoordinations() {
      //do we support geolocation
      if (!("geolocation" in navigator)) {
        this.errorStr = "Geolocation is not available.";
        return;
      }
      this.gettingLocation = true;
      // get position
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.gettingLocation = false;
          this.location = pos;
          let theDistance = this.calcCrow(
            this.location.coords.latitude,
            this.location.coords.longitude,
            this.fee.restLat,
            this.fee.restLong
          );
          if (theDistance * 1000 < this.fee.circleOneDistance) {
            console.log("You are in circle 1");
            this.estimatedDeliveryFee = this.fee.circleOneDistancePrice;
          } else if (
            this.fee.circleTwoDistance != 0 &&
            theDistance * 1000 < this.fee.circleTwoDistance
          ) {
            console.log("You are in circle 2");
            this.estimatedDeliveryFee = this.fee.circleTwoDistancePrice;
          } else if (
            this.fee.circleThreeDistance != 0 &&
            theDistance * 1000 < this.fee.circleThreeDistance
          ) {
            console.log("You are in circle 3");
            this.estimatedDeliveryFee = this.fee.circleThreeDistancePrice;
          } else {
            console.log("Your are out");
            this.outOfZone = true;
          }

          console.log("Lat " + this.location.coords.latitude);
          console.log("long " + this.location.coords.longitude);
        },
        (err) => {
          this.gettingLocation = false;
          this.errorStr = err.message;
        }
      );
    },
    scrollToUp() {
      $("html, body").animate({ scrollTop: 0 }, "fast");
    },

    submitOrder() {
      if (!this.orderBody.name) {
        this.formValid = false;
      } else {
        this.formValid = true;
      }
      if (!this.orderBody.address) {
        this.formValid = false;
      } else {
        this.formValid = true;
      }
      if (!this.orderBody.phoneNumber) {
        this.formValid = false;
      } else {
        if (this.validPhoneNumber(this.orderBody.phoneNumber)) {
          this.formValid = true;
        } else {
          this.formValid = false;
        }
      }

      if (this.formValid) {
        var question = "Are you sure you want to submit this order?";
        var feedback =
          "Order Submitted successfully, we will contact you soon!";

        if (this.$i18n.locale == "ku") {
          question = "ئایا دڵنیایی لە ناردنی داواكارییەكەت؟";
          feedback =
            "داواكارییەكەت بە سەركەوتووی نێردرا! بە زووترین كات پەیوەندیت پێوە ئەكەین";
        } else if (this.$i18n.locale == "ar") {
          question = "هل أنت متأكد أنك تريد تقديم هذا الطلب؟";
          feedback = " تم إرسال الطلب بنجاح ، سنتواصل معك قريبًا!";
        } else if (this.$i18n.locale == "tu") {
          question =
            "Sipariş başarıyla gönderildi, yakında sizinle iletişime geçeceğiz!";
          feedback = "Sipariş başarıyla gönderildi";
        }

        this.orderBody.deliveryFee = this.estimatedDeliveryFee;
        this.orderBody.discountRate = this.fee.discountRate;

        this.$confirm(question, "Question", "question")
          .then((r) => {
            console.log(r);
            this.orderBody.orderTotal = 0;
            this.orderBody.orderTotal = this.$store.state.finalTotal;

            this.orderBody.tableNumber = parseInt(0);
            this.orderBody.orderType = "Delivery";
            this.orderBody.orderBy = null;
            this.orderBody.MenuOrderSubs = this.$store.state.cart;

            this.$loading(true);
            this.$Axios
              .post(`${this.$hostname}/newOrder`, this.orderBody)
              .then(() => {
                this.$loading(false);
                localStorage.removeItem("cart");
                localStorage.removeItem("finalTotal");
                this.$store.commit("clearCart");

                this.orderBody.name = "";
                this.orderBody.phoneNumber = "";
                this.orderBody.address = "";
                this.orderBody.note = "";
                this.checkOutVisibility = false;

                this.$alert(feedback, "Success", "success");
                //this.$router.push("/Orders/New");
              })
              .catch((error) => {
                this.$alert(error, "Error", "error");
                console.log(error);
                this.$loading(false);
              });
          })
          .catch(() => {
            console.log("OK not selected.");
          });
      }
    },
    submiDiningtOrder() {
      var question = "Are you sure you want to submit this order?";
      var feedback = "Order Submitted successfully!";

      if (this.$i18n.locale == "ku") {
        question = "ئایا دڵنیایی لە ناردنی داواكارییەكەت؟";
        feedback = "داواكارییەكەت بە سەركەوتووی نێردرا! ";
      } else if (this.$i18n.locale == "ar") {
        question = "هل أنت متأكد أنك تريد تقديم هذا الطلب؟";
        feedback = " تم إرسال الطلب بنجاح !";
      } else if (this.$i18n.locale == "tu") {
        question =
          "Sipariş başarıyla gönderildi, yakında sizinle iletişime geçeceğiz!";
        feedback = "Sipariş başarıyla gönderildi";
      }

      this.orderBody.deliveryFee = this.fee.deliveryFee;
      this.orderBody.discountRate = this.fee.discountRate;

      this.$confirm(question, "Question", "question")
        .then((r) => {
          console.log(r);
          this.orderBody.orderTotal = 0;
          this.orderBody.orderTotal = this.$store.state.finalTotal;

          this.orderBody.tableNumber = this.routeTableNumber;
          (this.orderBody.orderType = "Dining"),
            (this.orderBody.orderBy = "Customer");

          this.orderBody.MenuOrderSubs = this.$store.state.cart;

          this.$loading(true);
          this.$Axios
            .post(`${this.$hostname}/newOrder`, this.orderBody)
            .then(() => {
              this.$loading(false);
              localStorage.removeItem("cart");
              localStorage.removeItem("finalTotal");
              this.$store.commit("clearCart");
              this.orderBody.note = "";
              this.checkOutVisibility = false;
              this.$alert(feedback, "Success", "success");
              //this.$router.push("/Orders/New");
            })
            .catch((error) => {
              this.$alert(error, "Error", "error");
              console.log(error);
              this.$loading(false);
            });
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },

    myNumber(number) {
      return new Intl.NumberFormat().format(number);
    },
    getMyLanguages() {
      this.$Axios
        .get(`${this.$hostname}/getLangSettings`)
        .then((Response) => {
          this.languages = Response.data;

          if (this.languages.isEn) {
            this.avEn = true;
          }
          if (this.languages.isKu) {
            this.avKu = true;
          }
          if (this.languages.isAr) {
            this.avAr = true;
          }
          if (this.languages.isTu) {
            this.avTu = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getLang() {
      if (this.$i18n.locale == "en") {
        return "En";
      } else if (this.$i18n.locale == "ku") {
        return "Ku";
      } else if (this.$i18n.locale == "ar") {
        return "Ar";
      } else if (this.$i18n.locale == "tu") {
        return "Tu";
      }
    },

    getCartLang() {
      if (this.$i18n.locale == "en") {
        return "";
      } else if (this.$i18n.locale == "ku") {
        return "Ku";
      } else if (this.$i18n.locale == "ar") {
        return "Ar";
      } else if (this.$i18n.locale == "tu") {
        return "Tu";
      }
    },

    switchLocale(locale) {
      this.$loading(true);
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        this.$session.set("Lang", locale);
        this.$Lang = locale;
        localStorage.setItem("theLang", locale);
      }
      this.$store.state.count = locale;
      $(".dropdown").removeClass("open");

      // if (this.$i18n.locale == "ku" || this.$i18n.locale == "ar") {
      // }
      setTimeout(() => {
        window.scrollTo(0, 0);
        this.$loading(false);
      }, 1000);
    },
    addQuantity(x) {
      this.cardObj = {
        itemId: null,
        name: null,
        price: null,
        imageLink: null,
        quantity: 1,
        menuOrderSubAddOns: [],
      };
      this.cardObj.itemId = x.itemId;
      this.cardObj.name = x.name;
      this.cardObj.price = x.price;
      this.cardObj.imageLink = x.imageLink;
      this.cardObj.menuOrderSubAddOns = x.menuOrderSubAddOns;
      this.$store.commit("addQuantity", this.cardObj);
    },
    reduceQuantity(x) {
      this.cardObj = {
        itemId: null,
        name: null,
        price: null,
        imageLink: null,
        quantity: 1,
        menuOrderSubAddOns: [],
      };
      this.cardObj.itemId = x.itemId;
      this.cardObj.name = x.name;
      this.cardObj.price = x.price;
      this.cardObj.imageLink = x.imageLink;
      this.cardObj.menuOrderSubAddOns = x.menuOrderSubAddOns;
      this.$store.commit("reduceQuantity", this.cardObj);
    },
    getContact() {
      this.$Axios
        .get(`${this.$hostname}/getContacts`)

        .then((Response) => {
          this.contact = Response.data;
        })
        .catch((error) => {
          console.log(error);
          this.$loading(false);
        });
    },
    validPhoneNumber(inputtxt) {
      var found = inputtxt.search(
        /^(\+{1}\d{2,3}\s?[(]{1}\d{1,3}[)]{1}\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}$/
      );
      if (found > -1) {
        return true;
      } else {
        return false;
      }
    },
    clearCart() {
      var question = "Are you sure you want to clear your cart?";
      var feedback = "Your cart has been cleaned";

      if (this.$i18n.locale == "ku") {
        question = "ئایا دڵنیایی لە بەتاڵكردنی سەبەتەكەت؟";
        feedback = "سەبەتەكەت بەتاڵكرایەوە";
      } else if (this.$i18n.locale == "ar") {
        question = "هل أنت متأكد أنك تريد مسح سلة الخاصة بك؟";
        feedback = "تم تنظيف سلة الخاصة بك";
      } else if (this.$i18n.locale == "tu") {
        question = "Sepetinizi temizlemek istediğinizden emin misiniz?";
        feedback = "Sepetiniz temizlendi";
      }
      this.$confirm(question, "Question", "question")
        .then(() => {
          this.$loading(false);
          localStorage.removeItem("cart");
          localStorage.removeItem("finalTotal");
          this.$store.commit("clearCart");
          this.checkOutVisibility = false;

          this.$alert(feedback, "Success", "success");
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
    // getFee() {
    //   this.$Axios
    //     .get(`${this.$hostname}/getFees`)
    //     .then((Response) => {
    //       this.fee = Response.data;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    getMyColors() {
      $("body").addClass("d-none");
      this.$Axios
        .get(`${this.$hostname}/getColors`)
        .then((Response) => {
          this.colors = Response.data;

          this.btnColor = this.colors.buttonColor
            ? this.colors.buttonColor
            : "#e54750";

          this.btnForeColor = this.colors.buttonForeColor
            ? this.colors.buttonForeColor
            : "#000000";

          this.mainBackColor = this.colors.mainBackgroundColor
            ? this.colors.mainBackgroundColor
            : "#FFFFFF";

          this.mainForeColor = this.colors.mainForeColor
            ? this.colors.mainForeColor
            : "#000000";

          $("body").removeClass("d-none");
          $("body").css("background-color", this.mainBackColor);

          // this.itemBackgrundColor = this.colors.cardBackgroundColor ? this.colors.cardBackgroundColor : '#ffffff';
          // this.itemTitleColor = this.colors.cardTitleColor ? this.colors.cardTitleColor : '#000000';
          // this.itemPriceColor = this.colors.cardPriceColor ? this.colors.cardPriceColor : '#d2691e';
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  mounted() {
     

    setTimeout(() => {
      $(document).ready(function () {
        $(window).on("scroll", function () {
          if (
            document.body.scrollTop > 20 ||
            document.documentElement.scrollTop > 20
          ) {
            $(".scrollBtn").removeClass("d-none");
          } else {
            $(".scrollBtn").addClass("d-none");
          }
        });
      });
    }, 300);

    //var tableNumber = new URLSearchParams(window.location.search).get("Table");
    this.routeTableNumber = parseInt(
      new URLSearchParams(window.location.search).get("Table")
    );
    //console.log(this.routeTableNumber);

    //this.routeTableNumber = this.$route.params.id

    this.getMyLanguages();
    if (localStorage.getItem("theLang")) {
      this.switchLocale(localStorage.getItem("theLang"));
    } else {
      let lang = this.$i18n.locale;
      this.switchLocale(lang);
    }

    setTimeout(() => {
      $(document).ready(function () {
        $(".button").click(function () {
          $(".dropdown a").removeClass("clicked");
          $(".dropdown a").children("span").removeClass("clicked");
          $(".arrow").toggleClass("open");
          $(".dropdown").toggleClass("open");
        });

        $(".dropdown a").click(function () {
          $(".dropdown a").removeClass("clicked");
          $(".dropdown a").children("span").removeClass("clicked");
          $(this).toggleClass("clicked");
          $(this).children("span").toggleClass("clicked");
        });
      });
    }, 300);

    this.getContact();
    this.getFee();
    this.getMyColors();
  },
  watch: {
    "reservation.date": function (val) {
      this.hourRange =[]
      let actualDate = new Date(val).getDay();
      console.log(this.availabilities[actualDate].day);
      let [day] = this.availabilities.filter(
        (av) => av.day == this.availabilities[new Date(val).getDay()].day
      );
      for (let i = day.from; i <= day.to; i++) {
        if (i >= new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Baghdad" })).getHours()) {
          this.hourRange.push(i);
        }
      }
    },
  },
};
</script>




