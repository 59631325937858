import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);
let myCart = window.localStorage.getItem("cart")
let myFinal = window.localStorage.getItem("finalTotal");

export const store = new Vuex.Store({
    state: {
        count: 15,
        cart: myCart ? JSON.parse(myCart) : [],
        finalTotal: myFinal ? parseInt(myFinal) : 0,

    },
    mutations: {
        addToCard(state, item) {
            //console.log("sub " + item.menuOrderSubAddOns.length)
            let found = state.cart.find(prodct => prodct.itemId == item.itemId);

            let foundNoAddOn = state.cart.find(prodct => prodct.itemId == item.itemId && prodct.menuOrderSubAddOns.length == 0);
            //let foundWithAddOn = state.cart.find(prodct => prodct.itemId == item.itemId && prodct.menuOrderSubAddOns.length > 0);


            if (found) {
                if (item.menuOrderSubAddOns.length == 0) {
                    if (foundNoAddOn) {
                        foundNoAddOn.quantity++
                    } else {
                        state.cart.push(item)
                    }

                } else if (item.menuOrderSubAddOns.length > 0) {

                    let foundObject = state.cart.find(prodct => JSON.stringify(prodct.menuOrderSubAddOns) == JSON.stringify(item.menuOrderSubAddOns));
                    console.log(JSON.stringify(foundObject))
                    if (foundObject) {
                        foundObject.quantity++;
                    } else {
                        state.cart.push(item);
                    }

                    // state.cart.forEach(element => {
                    //     console.log("*****************")
                    //     console.log("old object" + JSON.stringify(element.menuOrderSubAddOns));
                    //     console.log("new object" +  JSON.stringify(item.menuOrderSubAddOns));
                    //     console.log("*****************");

                    //     if(JSON.stringify(element.menuOrderSubAddOns) == JSON.stringify(item.menuOrderSubAddOns) ){
                    //         let myFound = state.cart.find(prodct => prodct.itemId == item.itemId);
                    //         myFound.quantity++;
                    //     }

                    // });


                    // if (foundWithAddOn) {
                    //     foundWithAddOn.quantity++
                    // } else {
                    //     state.cart.push(item)
                    // }

                }

            } else {
                state.cart.push(item)
            }


            state.finalTotal += item.price

            this.commit("storeData")

        },
        addQuantity(state, item) {
            console.log(item.menuOrderSubAddOns.length)
            if (item.menuOrderSubAddOns.length == 0) {
                let found = state.cart.find(prodct => prodct.itemId == item.itemId && prodct.menuOrderSubAddOns.length == 0);
                found.quantity++;
            } else {
                let foundObj = state.cart.find(prodct => JSON.stringify(prodct.menuOrderSubAddOns) == JSON.stringify(item.menuOrderSubAddOns));
                foundObj.quantity++;
            }


            state.finalTotal += item.price;
            this.commit("storeData")
        },

        reduceQuantity(state, item) {
            if (item.menuOrderSubAddOns.length == 0) {
                let found = state.cart.find(prodct => prodct.itemId == item.itemId && prodct.menuOrderSubAddOns.length == 0);
                console.log(JSON.stringify(found))
                if (found.quantity > 1) {
                    found.quantity--;
                } else {
                    //found.quantity =0 ;
                    let theIndex = this.state.cart.findIndex(
                        (prodct) => prodct == found
                      );

                      state.cart.splice(theIndex, 1)

                //   let myIndex = 0;
                  

                //     for (let index = 0; index < state.cart.length; index++) {


                //         if (item.nameEn === state.cart[index].nameEn && item.menuOrderSubAddOns.length === 0) {
                //             myIndex = index;

                //             console.log("index to be removed " + myIndex);
                //             break;
                //         }

                //     }
                //     state.cart.splice(myIndex, 1)
                }
            } else {
                let foundObj = state.cart.find(prodct => JSON.stringify(prodct.menuOrderSubAddOns) == JSON.stringify(item.menuOrderSubAddOns));
                if (foundObj.quantity > 1) {
                    foundObj.quantity--;
                } else {


                    let theIndex = this.state.cart.findIndex(
                        (prodct) => prodct == foundObj
                      );

                      state.cart.splice(theIndex, 1)
                }



            }


            //let found = state.cart.find(prodct => prodct.itemId == item.itemId);


            state.finalTotal -= item.price
            this.commit("storeData")
        },

        storeData(state) {
            window.localStorage.setItem("cart", JSON.stringify(state.cart))
            window.localStorage.setItem("finalTotal", state.finalTotal)
        },

        clearCart(state) {
            state.cart = []
            state.finalTotal = 0;
        },

        increment(state) {
            state.count++
        },

    },
    getters: {
        getCount: state => {
            return state.count
        },
    }

})