var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('main',[_vm._m(0),_c('div',{staticClass:"container margin_30_60",staticStyle:{"padding-bottom":"10px !important"}},[_vm._m(1),_c('carousel',{attrs:{"nav":false,"loop":true,"responsive":{
          0: { items: 3 },
          600: { items: 3 },
          900: { items: 5 },
        }}},[_c('div',{staticClass:"item_version_2",staticStyle:{"text-align":"center"}},[_c('a',{attrs:{"href":"grid-listing-filterscol.html"}},[_c('h3',[_vm._v("بەشەکە هەڵبژێرە")])])]),_c('div',{staticClass:"item_version_2",staticStyle:{"text-align":"center"}},[_c('a',{attrs:{"href":"grid-listing-filterscol.html"}},[_c('h3',{staticClass:"subactiveCata"},[_vm._v("خواردنەوە")])])]),_c('div',{staticClass:"item_version_2",staticStyle:{"text-align":"center"}},[_c('a',{attrs:{"href":"grid-listing-filterscol.html"}},[_c('h3',[_vm._v("نێرگەلە")])])]),_c('div',{staticClass:"item_version_2",staticStyle:{"text-align":"center"}},[_c('a',{attrs:{"href":"grid-listing-filterscol.html"}},[_c('h3',[_vm._v("کەیک")])])]),_c('div',{staticClass:"item_version_2",staticStyle:{"text-align":"center"}},[_c('a',{attrs:{"href":"grid-listing-filterscol.html"}},[_c('h3',[_vm._v("زەڵاتە")])])])])],1),_c('div',{staticClass:"container margin_30_60",staticStyle:{"padding-bottom":"0"}},[_vm._m(2),_c('carousel',{attrs:{"nav":false,"loop":true,"responsive":{
          0: { items: 3 },
          600: { items: 3 },
          900: { items: 5 },
        }}},_vm._l((_vm.subCategories),function(i){return _c('div',{key:i.id,staticClass:"item_version_2",staticStyle:{"text-align":"center"}},[_c('a',{staticStyle:{"padding":"5px 0"}},[_c('img',{staticStyle:{"margin":"auto","width":"100px","height":"100px","border-radius":"50%"},attrs:{"src":i.imageLink,"data-src":i.imageLink,"alt":"","width":"350","height":"450"},on:{"click":function($event){return _vm.doSelect($event)}}})]),_c('span',[_vm._v(_vm._s(i.title))])])}),0)],1),_c('div',{staticClass:"bg_gray",staticStyle:{"direction":"rtl"}},[_c('div',{staticClass:"container margin_60_40"},[_vm._m(3),_c('div',{staticClass:"menuGrid"},[_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_c('div',{staticClass:"item"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","data-toggle":"modal","data-target":"#exampleModalCenter"},on:{"click":function($event){return _vm.openModal()}}}),_c('img',{attrs:{"src":require("../assets/menuImg/img/9M.jpg"),"alt":""}}),_vm._m(12)])]),_vm._m(13)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade",attrs:{"id":"exampleModalCenter","tabindex":"-1","role":"dialog","aria-labelledby":"exampleModalCenterTitle","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header",staticStyle:{"text-align":"right","direction":"rtl"}},[_c('h5',{staticClass:"modal-title",attrs:{"id":"exampleModalLabel"}},[_vm._v("زەڵاتەی سیزەر")]),_c('button',{staticClass:"close",staticStyle:{"margin":"-1rem auto -1rem -1rem"},attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body",staticStyle:{"padding":"0 !important"}},[_c('img',{staticStyle:{"width":"80%","transform":"translate(13%, 6%)","border-radius":"8px","box-shadow":"0 0 12px rgb(0 0 0 / 30%)"},attrs:{"src":require("../assets/menuImg/img/1M.jpg"),"alt":""}})]),_c('div',{staticClass:"modal-footer d-block text-left px-4 pt-4",staticStyle:{"text-align":"right"}},[_c('h6',{staticStyle:{"text-align":"right"}},[_vm._v(" گۆشت, پەنیر, بیبەر, ")]),_c('hr',{staticStyle:{"border-color":"#e5cba4"}}),_c('p',{staticStyle:{"font-size":"10px","text-align":"right"}},[_vm._v(" ناوەزەترین تاقی بکەرەوە ")]),_c('div',{staticClass:"desc"},[_c('strong',{staticStyle:{"text-align":"right"}},[_vm._v(" نرخ:٣٠٠٠ دینار")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main_title center"},[_c('br'),_c('h2',[_vm._v("بەشەکە هەڵبژێرە")]),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main_title center"},[_c('span',[_c('em')]),_c('br'),_c('h2',[_vm._v("جۆرەکت هەڵبژێرە")]),_c('br'),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main_title"},[_c('span',[_c('em')]),_c('h2',{staticStyle:{"text-align":"right"}},[_vm._v("بەچێژترین هەڵبژێرە")]),_c('p',{staticStyle:{"text-align":"right"}},[_vm._v("تاقی بکەرەوە تامی ڕاستەقینە")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","data-toggle":"modal","data-target":"#exampleModalCenter"}}),_c('img',{attrs:{"src":require("../assets/menuImg/img/2M.jpg"),"alt":""}}),_c('div',{staticClass:"info"},[_c('h3',{staticStyle:{"text-align":"right"}},[_vm._v("زەڵاتەی سیزەر")]),_c('div',{staticClass:"desc"},[_c('strong',[_vm._v("نرخ:٢٠٠٠ دینار")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","data-toggle":"modal","data-target":"#exampleModalCenter"}}),_c('img',{attrs:{"src":require("../assets/menuImg/img/1M.jpg"),"alt":""}}),_c('div',{staticClass:"info"},[_c('h3',{staticStyle:{"text-align":"right"}},[_vm._v("برگر")]),_c('div',{staticClass:"desc"},[_c('strong',[_vm._v("نرخ:٣٠٠٠ دینار")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","data-toggle":"modal","data-target":"#exampleModalCenter"}}),_c('img',{attrs:{"src":require("../assets/menuImg/img/3M.png"),"alt":""}}),_c('div',{staticClass:"info"},[_c('h3',{staticStyle:{"text-align":"right"}},[_vm._v("بلوبێری")]),_c('div',{staticClass:"desc"},[_c('strong',[_vm._v("نرخ:٣٥٠٠ دینار")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","data-toggle":"modal","data-target":"#exampleModalCenter"}}),_c('img',{attrs:{"src":require("../assets/menuImg/img/4M.jpg"),"alt":""}}),_c('div',{staticClass:"info"},[_c('h3',{staticStyle:{"text-align":"right"}},[_vm._v("مێڵکشەیک")]),_c('div',{staticClass:"desc"},[_c('strong',[_vm._v("نرخ:٣٠٠٠ دینار")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","data-toggle":"modal","data-target":"#exampleModalCenter"}}),_c('img',{attrs:{"src":require("../assets/menuImg/img/5M.jpg"),"alt":""}}),_c('div',{staticClass:"info"},[_c('h3',{staticStyle:{"text-align":"right"}},[_vm._v("قاوە")]),_c('div',{staticClass:"desc"},[_c('strong',[_vm._v("نرخ:٢٠٠٠ دینار")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","data-toggle":"modal","data-target":"#exampleModalCenter"}}),_c('img',{attrs:{"src":require("../assets/menuImg/img/6M.jpg"),"alt":""}}),_c('div',{staticClass:"info"},[_c('h3',{staticStyle:{"text-align":"right"}},[_vm._v("پیتزا")]),_c('div',{staticClass:"desc"},[_c('strong',[_vm._v("نرخ: ٥٠٠٠ دینار")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","data-toggle":"modal","data-target":"#exampleModalCenter"}}),_c('img',{attrs:{"src":require("../assets/menuImg/img/7M.jpg"),"alt":""}}),_c('div',{staticClass:"info"},[_c('h3',{staticStyle:{"text-align":"right"}},[_vm._v("لازانیا")]),_c('div',{staticClass:"desc"},[_c('strong',[_vm._v("نرخ: ٣٠٠٠ دینار")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","data-toggle":"modal","data-target":"#exampleModalCenter"}}),_c('img',{attrs:{"src":require("../assets/menuImg/img/8M.png"),"alt":""}}),_c('div',{staticClass:"info"},[_c('h3',{staticStyle:{"text-align":"right"}},[_vm._v("سپاگێتی")]),_c('div',{staticClass:"desc"},[_c('strong',[_vm._v("نرخ: ٣٥٠٠ دینار")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('h3',{staticStyle:{"text-align":"right"}},[_vm._v("سوشی")]),_c('div',{staticClass:"desc"},[_c('strong',{staticStyle:{"text-align":"left"}},[_vm._v("نرخ: ٤٠٠٠ دینار")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade",attrs:{"id":"exampleModal","tabindex":"-1","role":"dialog","aria-labelledby":"exampleModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"exampleModalLabel"}},[_vm._v(" Modal title ")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_vm._v("...")]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(" Close ")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"}},[_vm._v(" Save changes ")])])])])])
}]

export { render, staticRenderFns }