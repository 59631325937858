import Vue from "vue";
import Router from "vue-router";
import En from "@/components/En";
import Ku from "@/components/Ku";


//======================
Vue.use(Router);

export default new Router({
    routes: [{
            path: "/:id?",
            name: "en",
            component: En
        },
        {
            path: "/Ku",
            name: "Ku",
            component: Ku
        },


    ]
});