<template>
  <div>
      <main>
      <!-- Modal -->
      <div
        class="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header" style="text-align: right; direction:rtl">
              <h5 class="modal-title" id="exampleModalLabel">زەڵاتەی سیزەر</h5>
              <button style="margin: -1rem auto -1rem -1rem ;"
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                
              >
                <span  aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" style="padding: 0 !important">
              <img
                style="
                  width: 80%;
                  transform: translate(13%, 6%);
                  border-radius: 8px;
                  box-shadow: 0 0 12px rgb(0 0 0 / 30%);
                "
                src="../assets/menuImg/img/1M.jpg"
                alt=""
              />
            </div>
            <div class="modal-footer d-block text-left px-4 pt-4" style="text-align: right;">
              <h6 style="text-align:right"> گۆشت, پەنیر, بیبەر, </h6>
              <hr style="border-color: #e5cba4" />
              <p style="font-size: 10px; text-align:right">
               ناوەزەترین تاقی بکەرەوە
              </p>
              <div class="desc">
                <strong style="text-align:right"> نرخ:٣٠٠٠ دینار</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /hero_single -->
      <div
        class="container margin_30_60"
        style="padding-bottom: 10px !important"
      >
        <div class="main_title center">
          <br />
          <h2>بەشەکە  هەڵبژێرە</h2>
          <br />
        </div>
        <!-- /main_title -->
        <carousel
          :nav="false"
          :loop="true"
          :responsive="{
            0: { items: 3 },
            600: { items: 3 },
            900: { items: 5 },
          }"
        >
          <div class="item_version_2" style="text-align: center">
            <a href="grid-listing-filterscol.html">
              <h3>بەشەکە هەڵبژێرە</h3>
            </a>
          </div>

          <div class="item_version_2" style="text-align: center">
            <a href="grid-listing-filterscol.html">
              <h3 class="subactiveCata">خواردنەوە</h3>
            </a>
          </div>
          <div class="item_version_2" style="text-align: center">
            <a href="grid-listing-filterscol.html">
              <h3>نێرگەلە</h3>
            </a>
          </div>
          <div class="item_version_2" style="text-align: center">
            <a href="grid-listing-filterscol.html">
              <h3>کەیک</h3>
            </a>
          </div>
          <div class="item_version_2" style="text-align: center">
            <a href="grid-listing-filterscol.html">
              <h3>زەڵاتە</h3>
            </a>
          </div>
        </carousel>
      </div>
      <div class="container margin_30_60" style="padding-bottom: 0">
        <div class="main_title center">
          <span><em></em></span>
          <br />
          <h2>جۆرەکت هەڵبژێرە</h2>
          <br />
          <br />
        </div>
        <!-- /main_title -->
        <carousel
          :nav="false"
          :loop="true"
          :responsive="{
            0: { items: 3 },
            600: { items: 3 },
            900: { items: 5 },
          }"
        >
          <div
            class="item_version_2"
            style="text-align: center"
            v-for="i in subCategories"
            :key="i.id"
          >
            <a style="padding: 5px 0">
              <img
                @click="doSelect($event)"
                class=""
                style="
                  margin: auto;
                  width: 100px;
                  height: 100px;
                  border-radius: 50%;
                "
                :src="i.imageLink"
                :data-src="i.imageLink"
                alt=""
                width="350"
                height="450"
              />

              <!-- <h3>Pizza</h3> -->
            </a>

            <span>{{ i.title }}</span>
          </div>
        </carousel>

        <!-- /carousel -->
      </div>
      <!-- /container -->

      <div class="bg_gray" style="direction: rtl; dir=rtl">
        <div class="container margin_60_40">
          <div class="main_title">
            <span><em></em></span>
            <h2 style="text-align:right">بەچێژترین هەڵبژێرە</h2>
            <p style="text-align:right">تاقی بکەرەوە تامی ڕاستەقینە</p>
          </div>
          <div class="menuGrid">
            <div class="item">
              <button
                type="button"
                class="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModalCenter"
              ></button>
              <img src="../assets/menuImg/img/2M.jpg" alt="" />
              <div class="info">
                <h3 style="text-align:right">زەڵاتەی سیزەر</h3>
                <!-- <span>Lettece, Chiken, Tomato, Cheese</span> -->

                <div class="desc">
                  <strong>نرخ:٢٠٠٠ دینار</strong>
                  <!-- <span class="discount">30%</span> -->
                </div>
              </div>
            </div>
            <div class="item">
              <button
                type="button"
                class="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModalCenter"
              ></button>
              <img src="../assets/menuImg/img/1M.jpg" alt="" />
              <div class="info">
                <h3 style="text-align:right">برگر</h3>

                <!-- <span>Bacon, Chilies, Chili, Cheese</span> -->
                <div class="desc">
                  <strong>نرخ:٣٠٠٠ دینار</strong>
                </div>
              </div>
            </div>
            <div class="item">
              <button
                type="button"
                class="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModalCenter"
              ></button>
              <img src="../assets/menuImg/img/3M.png" alt="" />
              <div class="info">
                <h3 style="text-align:right">بلوبێری</h3>
                <!-- <span>Lemon juice, Fresh blueberries, cane sugar</span> -->
                <div class="desc">
                  <strong>نرخ:٣٥٠٠ دینار</strong>
                </div>
              </div>
            </div>
            <div class="item">
              <button
                type="button"
                class="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModalCenter"
              ></button>
              <img src="../assets/menuImg/img/4M.jpg" alt="" />
              <div class="info">
                <h3 style="text-align:right">مێڵکشەیک</h3>
                <!-- <span>Vanilla ice cream, Maraschino, Milk</span> -->
                <div class="desc">
                  <strong>نرخ:٣٠٠٠ دینار</strong>
                </div>
              </div>
            </div>
            <div class="item">
              <button
                type="button"
                class="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModalCenter"
              ></button>
              <img src="../assets/menuImg/img/5M.jpg" alt="" />
              <div class="info">
                <h3 style="text-align:right">قاوە</h3>
                <!-- <span>Water kettles, Scales, Grinders</span> -->
                <div class="desc">
                  <strong>نرخ:٢٠٠٠ دینار</strong>
                </div>
              </div>
            </div>
            <div class="item">
              <button
                type="button"
                class="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModalCenter"
              ></button>
              <img src="../assets/menuImg/img/6M.jpg" alt="" />
              <div class="info">
                <h3 style="text-align:right">پیتزا</h3>
                <!-- <span
                  >Pepperoni, Mushroom, Sausage, Extra cheese, Black
                  olives</span -->
                <div class="desc">
                  <strong>نرخ: ٥٠٠٠ دینار</strong>
                </div>
              </div>
            </div>
            <div class="item">
              <button
                type="button"
                class="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModalCenter"
              ></button>
              <img src="../assets/menuImg/img/7M.jpg" alt="" />
              <div class="info">
                <h3 style="text-align:right">لازانیا</h3>

                <!-- <span>Italian sausage, beef, ricotta cheese, lasagna</span> -->
                <div class="desc">
                  <strong>نرخ: ٣٠٠٠ دینار</strong>
                </div>
              </div>
            </div>
            <div class="item">
              <button
                type="button"
                class="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModalCenter"
              ></button>
              <img src="../assets/menuImg/img/8M.png" alt="" />
              <div class="info">
                <h3 style="text-align:right">سپاگێتی</h3>
                <!-- <span
                  >Lean ground beef, Red wine, Tomato sauce, Beef broth</span
                > -->
                <div class="desc">
                  <strong>نرخ: ٣٥٠٠ دینار</strong>
                </div>
              </div>
            </div>
            <div class="item">
              <button
                type="button"
                class="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModalCenter"
                @click="openModal()"
              ></button>
              <img src="../assets/menuImg/img/9M.jpg" alt="" />
              <div class="info">
                <h3 style="text-align:right">سوشی</h3>
                <!-- <span>A topping, fish, Sushi rice</span> -->
                <div class="desc">
                  <strong style="text-align:left">نرخ: ٤٠٠٠ دینار</strong>
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Modal title
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">...</div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="button" class="btn btn-primary">
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="row add_bottom_25">
                    <div class="col col-lg-6 col-md-6 col-12">
                        <div class="list_home">
                            <ul>
                                <li>
                                    <a class="btn" data-toggle="modal" data-target="#exampleModalCenter">
                                        <figure>
                                            <div
                                                style="
                                                background-size: cover;
                                           height: 130px;
                                            background-repeat: no-repeat;
                                           background-image: url(admin_section/img/1M.jpg)">
                                            


                                            </div>
                                        </figure>
                                      

                                        <h3>La Monnalisa</h3>
                                        <small>8 Patriot Square E2 9NF</small>
                                        <ul>
                                           
                                        </ul>
                                    </a>
                                </li>
                                <li>
                                    <a href="detail-restaurant.html">
                                        <figure>
                                            <img src="img/location_list_placeholder.png"
                                                data-src="img/location_list_2.jpg" alt="" class="lazy" width="350"
                                                height="233">
                                        </figure>
                                        <div class="score"><strong>8.0</strong></div>
                                        <em>Mexican</em>
                                        <h3>Alliance</h3>
                                        <small>27 Old Gloucester St, 4563</small>
                                        <ul>
                                            <li><span class="ribbon off">-40%</span></li>
                                            <li>Average price $30</li>
                                        </ul>
                                    </a>
                                </li>
                                <li>
                                    <a href="detail-restaurant.html">
                                        <figure>
                                            <img src="img/location_list_placeholder.png"
                                                data-src="img/location_list_3.jpg" alt="" class="lazy" width="350"
                                                height="233">
                                        </figure>
                                        <div class="score"><strong>9.0</strong></div>
                                        <em>Sushi - Japanese</em>
                                        <h3>Sushi Gold</h3>
                                        <small>Old Shire Ln EN9 3RX</small>
                                        <ul>
                                            <li><span class="ribbon off">-25%</span></li>
                                            <li>Average price $20</li>
                                        </ul>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col col-lg-6 col-md-6 col-12">
                        <div class="list_home">
                            <ul>
                                <li>
                                    <a href="detail-restaurant.html">
                                        <figure>
                                            <img src="img/location_list_placeholder.png"
                                                data-src="img/location_list_4.jpg" alt="" class="lazy" width="350"
                                                height="233">
                                        </figure>
                                        <div class="score"><strong>9.5</strong></div>
                                        <em>Vegetarian</em>
                                        <h3>Mr. Pepper</h3>
                                        <small>27 Old Gloucester St, 4563</small>
                                        <ul>
                                            <li><span class="ribbon off">-30%</span></li>
                                            <li>Average price $20</li>
                                        </ul>
                                    </a>
                                </li>
                                <li>
                                    <a href="detail-restaurant.html">
                                        <figure>
                                            <img src="img/location_list_placeholder.png"
                                                data-src="img/location_list_5.jpg" alt="" class="lazy" width="350"
                                                height="233">
                                        </figure>
                                        <div class="score"><strong>8.0</strong></div>
                                        <em>Chinese</em>
                                        <h3>Dragon Tower</h3>
                                        <small>22 Hertsmere Rd E14 4ED</small>
                                        <ul>
                                            <li><span class="ribbon off">-50%</span></li>
                                            <li>Average price $35</li>
                                        </ul>
                                    </a>
                                </li>
                                <li>
                                    <a href="detail-restaurant.html">
                                        <figure>
                                            <img src="img/location_list_placeholder.png"
                                                data-src="img/location_list_6.jpg" alt="" class="lazy" width="350"
                                                height="233">
                                        </figure>
                                        <div class="score"><strong>8.5</strong></div>
                                        <em>Pizza - Italian</em>
                                        <h3>Bella Napoli</h3>
                                        <small>135 Newtownards Road BT4</small>
                                        <ul>
                                            <li><span class="ribbon off">-45%</span></li>
                                            <li>Average price $25</li>
                                        </ul>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> -->
          <!-- /row -->

          <!-- /banner -->
        </div>
      </div>
      <!-- /bg_gray -->

      <!-- /shape_element_2 -->
    </main>
  </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
import carousel from "vue-owl-carousel";
import $ from "jquery";
export default {
  name: "App",
  components: { carousel },
  data() {
    return {
      isClicked: false,
      subCategories: [
        {
          id: 1,
          title: "زەڵاتە",
          imageLink: "../assets/menuImg/img/salad.png",
        },
        {
          id: 2,
          title: "پیتزا",
          imageLink: "../assets/menuImg/img/pizza.png",
        },
        {
          id: 3,
          title: "خواردنەوە",
          imageLink: "../assets/menuImg/img/drink.png",
        },
        {
          id: 4,
          title: "فاست فوود",
          imageLink: "../assets/menuImg/img/fastfood.png",
        },
        {
          id: 5,
          title: "مقەبیلات",
          imageLink: "../assets/menuImg/img/appetizer.png",
        },
        {
          id: 6,
          title: "بەرگر",
          imageLink: "../assets/menuImg/img/burger.png",
        },
      ],
    };
  },

  methods: {
    doSelect(event) {
      console.log(event.target);
      let elems = document.querySelectorAll(".item_version_2 a img");
      elems.forEach((item) => {
        item.classList.remove("subactive");
      });
      event.target.classList.add("subactive");
    },

    openModal() {
      $("body").css("overflow", "hidden");
      $("html").css("overflow", "hidden");

    
    },

    closeModal(){
      // $("body").css("overflow-y", "scroll");
      $("html").css("overflow-y", "scroll");
    }
  },
  mounted() {
    this.$loading(true);

    setTimeout(() => {
        this.$loading(false);
    }, 300);

 

//   $(document).ready(function(){
//     $(".modal-backdrop").on('click', function(){
//             console.log("click!!!");
//         });
// });


  
  },
  // components: {
  //   HelloWorld
  // }
};
</script>

<style>

</style>