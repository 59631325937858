import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n';
import VueSession from 'vue-session'
import VueLoading from "vuejs-loading-plugin";
import router from "./router";
import VueSimpleAlert from "vue-simple-alert";

import loader from "./components/cusLoader"

import { store } from './store'
import VueToastify from "vue-toastify";


import Axios from "axios";

//Vue Leafleat
import { LMap, LTileLayer, LMarker, LCircle, LPopup } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-circle', LCircle);
Vue.component('l-popup', LPopup);

import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.prototype.$hostname = "https://www.qasiralzyafa.com"
//Vue.prototype.$hostname = "https://localhost:44351"
Vue.prototype.$Axios = Axios;

Vue.use(VueSession)
Vue.use(VueToastify);
Vue.use(VueSimpleAlert);

Vue.use(VueLoading, {
    dark: true, // default false
   customLoader: loader,
    background: "rgba(255,255,255,0.5)" // set custom background
});
Vue.config.productionTip = false



new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
}).$mount('#app')